import gql from 'graphql-tag';

export const MenuCard = gql`
  fragment MenuCard on Restaurant {
    menuCard {
      id
      url
    }
  }
`;

export const BizCard = gql`
  fragment BizCard on Restaurant {
    bizCardBackSideMainTitle {
      text
    }
    bizCardBackSideMainSubTitle {
      text
    }
    bizCardBackSideImg {
      id
      url
    }
    bizCardFront {
      url
    }
    bizCardBack {
      url
      id
    }
  }
`;

export const BIZCARD_BACKSIDE_IMG = gql`
  fragment BIZCARD_BACKSIDE_IMG on Restaurant {
    bizCardBackSideImg {
      id
      url
    }
  }
`;
