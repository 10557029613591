import gql from 'graphql-tag';

export const updateWebsiteData = gql`
  mutation updateWebsite($input: UpdateWebsiteContentInput!) {
    updateWebsite(input: $input) {
      version
      url
      bizId
      keywords
      legalEntityName
      googleAnalyticsTrackingId
      googleAdsId
      seoPageTitle
      seoPageDescription
      googleSiteVerification
      menuHeadline
      displayImage {
        id
        url
      }
      headline1
      headline2
      customDomain
      about {
        picture {
          id
          url
        }
        title
        description
      }
      menuPicture {
        id
        url
      }
      fbLink
      instaLink
      yelpLink
      testimonials {
        name
        text
        rating
      }
      orderPlatform {
        id
        name
        url
      }
    }
  }
`;

export const createWebsiteData = gql`
  mutation createWebsite($input: CreateWebsiteContentInput!) {
    createWebsite(input: $input) {
      version
      url
      bizId
      customDomain
      keywords
      googleAnalyticsTrackingId
      googleAdsId
      seoPageTitle
      seoPageDescription
      googleSiteVerification
      menuHeadline
      legalEntityName
      displayImage {
        id
        url
      }
      headline1
      headline2
      about {
        picture {
          id
          url
        }
        title
        description
      }
      menuPicture {
        id
        url
      }
      fbLink
      instaLink
      yelpLink
      testimonials {
        name
        text
        rating
      }
      orderPlatform {
        id
        name
        url
      }
    }
  }
`;
