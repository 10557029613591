import { Box, Grid, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStyles } from '../style';
import { useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';
import GetAppIcon from '@material-ui/icons/GetApp';
import BusinessCardInfo from './BusinessCardInfo';
import { FirebaseContext } from 'fbase';
import { useContext } from 'react';
import { useStore } from 'store';
import { IRestaurantBizCardInfo } from '../types';
import NoInfo from '../NoInfo';
import { useCreateImageUploader } from 'graphql/hooks/images.hooks';
import S3Image from 'components/S3Image';
import restaurantEmptyIcon from 'assets/img/restaurant-empty-icon.png';

interface IProps {
  restaurantId: string;
}

const BusinessCardMSP = ({ restaurantId }: IProps) => {
  const classes = useStyles();
  const { data } = useGetRestaurantById(restaurantId, 'ALL');
  const { dispatch } = useStore();
  const { createImageUploadUrl, uploadImage } = useCreateImageUploader();
  const { firebase } = useContext(FirebaseContext);
  const [initialValues, setInitialValues] = useState<IRestaurantBizCardInfo>({
    name: '',
    description: '',
    webSite: '',
    phone: '',
    address: {
      text: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      lat: 0,
      lng: 0,
      street: ''
    },
    logo: null,
    images: null,
    logoImageData: null,
    subName: '',
    bizCardBackSideMainTitle: '',
    bizCardBackSideMainSubTitle: '',
    bizCardBackSideImg: null
  });

  useEffect(() => {
    if (data && data.ownerGetRestaurantById) {
      const { name, subName, description, bizCardBackSideImg, phone, address, logo, images, bizCardBackSideMainTitle, bizCardBackSideMainSubTitle, webSite } = data.ownerGetRestaurantById;
      setInitialValues({
        name: name ? name[0].text : '',
        description: description ? description[0].text : '',
        subName: subName ? subName[0].text : '',
        phone: phone || '',
        webSite: webSite || '',
        images: images,
        address: address
          ? {
              text: address.text,
              city: address.city,
              state: address.state,
              country: address.country,
              zipcode: address.zipcode,
              lat: address.lat,
              lng: address.lng,
              street: address.street
            }
          : {
              text: '',
              city: '',
              state: '',
              country: '',
              zipcode: '',
              lat: 0,
              lng: 0,
              street: ''
            },
        logo: logo ? logo : null,
        logoImageData: null,
        bizCardBackSideMainTitle: bizCardBackSideMainTitle ? bizCardBackSideMainTitle[0].text : '',
        bizCardBackSideMainSubTitle: bizCardBackSideMainSubTitle ? bizCardBackSideMainSubTitle[0].text : '',
        bizCardBackSideImg: bizCardBackSideImg ? bizCardBackSideImg : null
      });
    }
  }, [data]);
  const [frontImgUrl, setFrontImgUrl] = useState('');
  const [backImgUrl, setBackImgUrl] = useState('');

  useEffect(() => {
    const startLoader = () => {
      dispatch({
        type: 'LOADER_INCREMENT_COUNT'
      });
    };
    const endLoader = () => {
      dispatch({
        type: 'LOADER_DECREMENT_COUNT'
      });
    };
    if (data && data.ownerGetRestaurantById) {
      const { bizCardBack, bizCardFront } = data.ownerGetRestaurantById;
      if (bizCardBack && bizCardFront && !(initialValues && (initialValues.name === '' || initialValues.address.text === '' || initialValues.phone === ''))) {
        startLoader();
        setFrontImgUrl('');
        setBackImgUrl('');
        const path = `/create_business_card/${bizCardBack.id}`;
        firebase
          ?.getRealtimeDB()
          .ref(path)
          .on('value', (val) => {
            const dbStatusValue = val.val();
            if (dbStatusValue) {
              if (dbStatusValue.front && dbStatusValue.back && dbStatusValue.front.status === 'done' && dbStatusValue.back.status === 'done') {
                setFrontImgUrl(bizCardFront.url);
                setBackImgUrl(bizCardBack.url);
                endLoader();
                firebase
                  ?.getRealtimeDB()
                  .ref(path)
                  .off('value');
              }
            }
          });
      }
    }
  }, [data, dispatch, firebase, initialValues]);

  const getUploadId = async (filename: string, imageData: string) => {
    try {
      const { data: uploadImageResponse } = await createImageUploadUrl({
        variables: {
          input: {
            usage: 'BIZ_CARD_BACK_SIDE',
            fileName: filename
          }
        }
      });

      if (uploadImageResponse && uploadImageResponse.createImageUploadUrl) {
        const { id, url } = uploadImageResponse.createImageUploadUrl;
        await uploadImage(url, imageData);

        return id;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  if (data && data.ownerGetRestaurantById) {
    return initialValues && (initialValues.name === '' || initialValues.address.text === '' || initialValues.phone === '') ? (
      <NoInfo text=" You need to provide restaurant details (Name, Address, Phone, logo and atleast one gallary image) to generate a business card." restaurantId={restaurantId} />
    ) : (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6} lg={6} md={6}>
            <BusinessCardInfo initialValues={initialValues} restaurantId={restaurantId} getUploadId={getUploadId} />
          </Grid>

          <Grid item xs={6} lg={6} md={6}>
            <Box paddingY={1} marginBottom={1}>
              <Typography variant="h6"> Front Side</Typography>
            </Box>
            {frontImgUrl && frontImgUrl !== '' ? (
              <Box display={'flex'} flexDirection="column" alignItems="center" justifyContent={'center'}>
                <Box width="60%" marginBottom={2} height={frontImgUrl ? 'auto' : '300px'}>
                  <S3Image src={frontImgUrl} className={classes.cardStyle} defaultImage={restaurantEmptyIcon} />
                </Box>
                <Box display="flex" justifyContent="center">
                  <a href={frontImgUrl} download="business-card.png">
                    <Button variant="contained" color="primary" disableElevation={true} startIcon={<GetAppIcon />}>
                      Download
                    </Button>
                  </a>
                </Box>
              </Box>
            ) : (
              <Box width={'100%'} justifyContent="center" alignItems={'center'} display="flex" padding={3}>
                <Typography align="center" variant="body1">
                  Loading preview...
                </Typography>
              </Box>
            )}

            <Box paddingY={1} marginBottom={1}>
              <Box paddingY={1} marginBottom={1}>
                <Typography variant="h6">Back Side</Typography>
              </Box>
              {backImgUrl && backImgUrl !== '' ? (
                <Box display={'flex'} flexDirection="column" alignItems="center" justifyContent={'center'}>
                  <Box width="60%" marginBottom={2} height={backImgUrl ? 'auto' : '300px'}>
                    <S3Image src={backImgUrl} className={classes.cardStyle} defaultImage={restaurantEmptyIcon} />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <a href={backImgUrl} download="business-card.png">
                      <Button variant="contained" color="primary" disableElevation={true} startIcon={<GetAppIcon />}>
                        Download
                      </Button>
                    </a>
                  </Box>
                </Box>
              ) : (
                <Box width={'100%'} justifyContent="center" alignItems={'center'} display="flex" padding={3}>
                  <Typography align="center" variant="body1">
                    Loading preview...
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
  return null;
};

export default BusinessCardMSP;
