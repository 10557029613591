import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  websiteUrl: Yup.string()
    .max(60, 'Maximun 60 Characters!')
    .required('Please enter website domain'),

  facebookLink: Yup.string()
    .matches(/(?:http:\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(\d.*))?([\w\-]*)?/, 'Enter Correct url')
    .nullable(),
  instagramLink: Yup.string()
    .matches(/(?:http:\/\/)?(?:www.)?instagram.com\/[a-zA-z-_0-9]/, 'Enter Correct url')
    .nullable(),
  yelpLink: Yup.string()
    .matches(/(?:http:\/\/)?(?:www.)?yelp.com\/[a-zA-z-_0-9]/, 'Enter Correct url')
    .nullable(),
  seoPageTitle: Yup.string()
    .required('Page Title is required!')
    .max(75, 'Maximum 75 Characters are allowed'),
  seoPageDescription: Yup.string()
    .required('Page Meta Description is required!')
    .min(50, 'Provide atleast 50 Characters')
    .max(200, 'Maximum 200 Characters are allowed'),
  headline1: Yup.string()
    .max(200, 'Maximun 200 Characters')
    .required('Please enter Homepage headline 1'),
  headline2: Yup.string()
    .max(200, 'Maximun 200 Characters')
    .required('Please enter Homepage headline 2'),

  about: Yup.array()
    .of(
      Yup.object().shape({
        heading: Yup.string()
          .max(200, 'Maximun 200 Characters')
          .required('Please Enter Heading'),
        description: Yup.string()
          .max(3000, 'Maximun 3000 Characters')
          .required('Please Enter Description')
      })
    )
    .required('Must have Data'),

  menuDescription: Yup.string()
    .max(200, 'Maximun 200 Characters')
    .required('Please Enter Menu Description'),

  testimonials: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Please enter name'),
      text: Yup.string()
        .max(400, 'Maximun 400 Characters')
        .required('Please enter review'),
      rating: Yup.number()
        .min(1, 'Incorrect rating')
        .max(6, 'Incorrect rating')
        .required('Required')
    })
  ),
  displayImageData: Yup.object().when('displayImage', {
    is: (val) => {
      return val === null;
    },
    then: Yup.object().shape({
      filename: Yup.string().required(),
      imageFiledata: Yup.string().required()
    }),
    otherwise: Yup.object()
      .notRequired()
      .nullable()
  }),
  blog: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required('Please enter title'),
      text: Yup.string().required('Please enter article body')
    })
  ),
  orderPlatform: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Please enter platform name'),
      url: Yup.string().required('Please enter platform url')
    })
  )
});
export default ValidationSchema;
