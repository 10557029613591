import React, { useState } from 'react';
import { useStore } from 'store';
import { useGetRestaurantById, useUpdateRestaurantMutation } from 'graphql/hooks/restaurant.hooks';
import { useSnackbar } from 'notistack';
import { useFormik, getIn, FormikProps } from 'formik';
import { useStyles } from './style';
import { Box, Typography, Button, Divider, withStyles, useTheme, Tabs, Tab, Switch, Dialog } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { IPaymentSetting, ITabOptions, IInitialValueType, IDeliverySetting } from './types';
import { setInitialValues, setInitialDeliverySettings } from './utils';
import Setup from './Setup';
import validations from './validations';
import { map, filter } from 'lodash';

const defaultPaymentSetting: IPaymentSetting = {
  onlinePaymentTypes: [],
  onsitePaymentTypes: [],
  tipPercentOptions: [0, 10, 15, 20],
  tipDefaultPercentIndex: 2,
  onlinePaymentFeeAmount: '0.30',
  onlinePaymentFeeThreshold: '30.00',
  addOnlinePaymentFee: false,
  enable: false,
  disableOrderDetailDisplay: false
};

const defaultDeliverySetting: IDeliverySetting = {
  waitMinutes: 45,
  freeDeliveryAmount: '100.00',
  minDeliveryAmount: '25.00',

  fees: [
    {
      mile: 3,
      fee: '4.50'
    },
    {
      mile: 5,
      fee: '6.00'
    },
    {
      mile: 6,
      fee: '7.00'
    },
    {
      mile: 7,
      fee: '8.00'
    }
  ]
};

const PaymentSetup = () => {
  const classes = useStyles();

  const {
    state: { restaurant }
  } = useStore();

  const snackbar = useSnackbar();

  const theme = useTheme();

  const StyledTabs = withStyles({
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: '3px'
    }
  })((props: any) => <Tabs {...props} />);

  const [activeTab, setActiveTab] = useState<ITabOptions>('takeoutPaymentSetting');

  const [openDialog, setOpenDialog] = useState(false);

  const { data } = useGetRestaurantById(restaurant, 'PAYMENT_SETTINGS');

  const { updateRestaurant } = useUpdateRestaurantMutation('PAYMENT_SETTINGS');

  const {
    takeoutPaymentSetting,
    dineoutPaymentSetting,
    deliveryPaymentSetting,
    hasStripeAccount,
    deliverySetting,
    enableTakeoutOrder,
    enableDineOutOrder,
    enableDeliveryOrder,
    takeoutWaitMinutes,
    opsSetting,
    bizHours,
    stripeInProgress
  } = (data && data.ownerGetRestaurantById) || {};

  const { disableTakeoutOrderDetailDisplay = false, disableDeliveryOrderDetailDisplay = false, disableDineoutOrderDetailDisplay = false } = opsSetting || {
    disableTakeoutOrderDetailDisplay: false,
    disableDeliveryOrderDetailDisplay: false,
    disableDineoutOrderDetailDisplay: false
  };

  const initialValues: IInitialValueType = {
    takeoutPaymentSetting: takeoutPaymentSetting
      ? { ...setInitialValues(takeoutPaymentSetting), enable: enableTakeoutOrder, disableOrderDetailDisplay: disableTakeoutOrderDetailDisplay }
      : defaultPaymentSetting,
    dineoutPaymentSetting: dineoutPaymentSetting
      ? { ...setInitialValues(dineoutPaymentSetting), enable: enableDineOutOrder, disableOrderDetailDisplay: disableDineoutOrderDetailDisplay }
      : defaultPaymentSetting,
    deliveryPaymentSetting: deliveryPaymentSetting
      ? { ...setInitialValues(deliveryPaymentSetting), enable: enableDeliveryOrder, disableOrderDetailDisplay: disableDeliveryOrderDetailDisplay }
      : defaultPaymentSetting,
    hasStripeAccount: hasStripeAccount,
    stripeInProgress: stripeInProgress,
    deliverySetting: deliverySetting ? setInitialDeliverySettings(deliverySetting) : defaultDeliverySetting,
    takeoutWaitMinutes: takeoutWaitMinutes ? takeoutWaitMinutes : 10
  };

  const formik: FormikProps<IInitialValueType> = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validations,
    onSubmit: () => {}
  });

  const { values, errors, touched } = formik;

  const onSubmit = async () => {
    const {
      onlinePaymentTypes,
      onsitePaymentTypes,
      tipDefaultPercentIndex,
      tipPercentOptions,
      onlinePaymentFeeAmount,
      onlinePaymentFeeThreshold,
      addOnlinePaymentFee,
      enable,
      disableOrderDetailDisplay
    } = values[activeTab];

    const { deliverySetting, takeoutWaitMinutes } = values;

    // const { waitMinutes, minDeliveryAmount, freeDeliveryAmount, fees } = deliverySetting;

    const response = await updateRestaurant({
      variables: {
        input: {
          [activeTab]: {
            onlinePaymentTypes,
            onsitePaymentTypes,
            tipPercentOptions,
            tipDefaultPercent: tipPercentOptions[tipDefaultPercentIndex],
            onlinePaymentFee: addOnlinePaymentFee
              ? {
                  amount: {
                    currencyCode: 'usd',
                    strValue: onlinePaymentFeeAmount
                  },
                  threshold: {
                    currencyCode: 'usd',
                    strValue: onlinePaymentFeeThreshold
                  }
                }
              : null
          },
          // deliverySetting:
          //   activeTab === 'deliveryPaymentSetting'
          //     ? {
          //         waitMinutes,
          //         minDeliveryAmount: {
          //           currencyCode: 'usd',
          //           strValue: minDeliveryAmount
          //         },
          //         freeDeliveryAmount: {
          //           currencyCode: 'usd',
          //           strValue: freeDeliveryAmount
          //         },
          //         fees: map(fees, ({ mile, fee }) => {
          //           return {
          //             mile,
          //             fee: {
          //               currencyCode: 'usd',
          //               strValue: fee
          //             }
          //           };
          //         })
          //       }
          //     : undefined,
          enableTakeoutOrder: activeTab === 'takeoutPaymentSetting' ? enable : undefined,
          enableDineOutOrder: activeTab === 'dineoutPaymentSetting' ? enable : undefined,
          // enableDeliveryOrder: activeTab === 'deliveryPaymentSetting' ? enable : undefined,
          takeoutWaitMinutes,
          opsSetting: {
            disableTakeoutOrderDetailDisplay: activeTab === 'takeoutPaymentSetting' ? disableOrderDetailDisplay : disableTakeoutOrderDetailDisplay,
            //disableDeliveryOrderDetailDisplay: activeTab === 'deliveryPaymentSetting' ? disableOrderDetailDisplay : disableDeliveryOrderDetailDisplay,
            disableDineoutOrderDetailDisplay: activeTab === 'dineoutPaymentSetting' ? disableOrderDetailDisplay : disableDineoutOrderDetailDisplay
          }
        }
      }
    });

    if (response) {
      snackbar.enqueueSnackbar('Payment settings updated successfully !', {
        variant: 'success'
      });
    } else {
      snackbar.enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error'
      });
    }
  };

  const disableSaveBtn = () => {
    if (activeTab === 'deliveryPaymentSetting') {
      return (!!getIn(touched, activeTab) && !!getIn(errors, activeTab)) || !!getIn(errors, 'deliverySetting');
    }

    return !!getIn(touched, activeTab) && !!getIn(errors, activeTab);
  };

  const enableOrderType = (checked: boolean) => {
    let orderTypeBizHours = [];
    if (activeTab === 'takeoutPaymentSetting') {
      orderTypeBizHours = filter(bizHours ? bizHours : [], ({ serviceType }) => serviceType === 'RESTAURANT_PICK_UP');
    }
    if (activeTab === 'dineoutPaymentSetting') {
      orderTypeBizHours = filter(bizHours ? bizHours : [], ({ serviceType }) => serviceType === 'RESTAURANT_DINE_OUT');
    }
    if (activeTab === 'deliveryPaymentSetting') {
      orderTypeBizHours = filter(bizHours ? bizHours : [], ({ serviceType }) => serviceType === 'RESTAURANT_DELIVERY');
    }

    if (checked === true && !orderTypeBizHours.length) {
      setOpenDialog(true);
    } else {
      formik.setValues({
        ...values,
        [activeTab]: {
          ...values[activeTab],
          enable: checked
        }
      });
    }
  };

  if (data && data.ownerGetRestaurantById) {
    return (
      <Box padding={1} bgcolor="white">
        <Box display="flex" alignItems="center">
          <Box>
            <Typography variant="h5">Payment Setup</Typography>
          </Box>
          <Box flexGrow={1} textAlign="right">
            <Button type="submit" variant="contained" color="primary" startIcon={<SaveIcon />} disableElevation={true} disabled={disableSaveBtn()} onClick={onSubmit}>
              Save
            </Button>
          </Box>
        </Box>
        <Divider className={classes.headerDivider} />
        <Box display="flex">
          <Box flexGrow={1}>
            <StyledTabs value={activeTab} onChange={(_e: any, index: any) => setActiveTab(index)}>
              <Tab label="Take out" value="takeoutPaymentSetting" />
              <Tab label="Dine out" value="dineoutPaymentSetting" />
              {/* <Tab label="Delivery" value="deliveryPaymentSetting" /> */}
            </StyledTabs>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="body2">Disable Order details display:</Typography>
            <Switch
              checked={values[activeTab].disableOrderDetailDisplay}
              onChange={(_e: any, checked: boolean) => {
                formik.setValues({
                  ...values,
                  [activeTab]: {
                    ...values[activeTab],
                    disableOrderDetailDisplay: checked
                  }
                });
              }}
              color="secondary"
            />
          </Box>
          <Box display="flex" alignItems="center">
            {activeTab === 'takeoutPaymentSetting' && <Typography variant="body2">Enable Takeout:</Typography>}
            {activeTab === 'dineoutPaymentSetting' && <Typography variant="body2">Enable Dine out:</Typography>}
            {activeTab === 'deliveryPaymentSetting' && <Typography variant="body2">Enable Delivery:</Typography>}
            <Switch checked={values[activeTab].enable} onChange={(_e: any, checked: boolean) => enableOrderType(checked)} color="secondary" />
          </Box>
        </Box>
        <Box padding={2}>
          {activeTab === 'takeoutPaymentSetting' && <Setup formik={formik} field="takeoutPaymentSetting" />}
          {activeTab === 'dineoutPaymentSetting' && <Setup formik={formik} field="dineoutPaymentSetting" />}
          {activeTab === 'deliveryPaymentSetting' && <Setup formik={formik} field="deliveryPaymentSetting" />}
        </Box>
        <Dialog open={openDialog}>
          <Box padding={2}>
            <Typography variant="body2">Business hours not found for given order type. Please set to enable.</Typography>
            <Box display="flex" justifyContent="center" paddingY={1}>
              <Button variant="contained" color="primary" disableElevation={true} onClick={() => setOpenDialog(false)}>
                OK
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
    );
  }

  return null;
};

export default PaymentSetup;
