import React, { useState } from 'react';
import { useStyles } from './style';
import { Typography, Button, Grid, Switch, TextField, Checkbox, Select, MenuItem, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import * as _ from 'lodash';
import { IBizHour, IBizHourSlot } from '../../types';
import { Dialog } from '@lokobee/lokobee-ui';
import TimePicker from './TimePicker';
interface IProps {
  open: boolean;
  service: IBizHour;
  hours: IBizHour[];
  handleClose: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editService: (service: IBizHour) => void;
}

const BusinessHoursDialog = ({ open, service, handleClose, editService, hours, setOpen }: IProps) => {
  const classes = useStyles();

  const [businessHours, setBusinessHours] = useState<IBizHourSlot[]>(service.hours);

  const [copying, setCopying] = useState(false);

  const [selectedDays, setSelectedDays] = useState<number[]>([]);

  const [copyDay, setCopyDay] = useState<IBizHourSlot>();

  const [serviceName, setServiceName] = useState(service.serviceName);

  const serviceTypes = [
    { label: 'Pickup', value: 'RESTAURANT_PICK_UP' },
    { label: 'Dine In', value: 'RESTAURANT_DINE_IN' },
    { label: 'Dine Out', value: 'RESTAURANT_DINE_OUT' },
    { label: 'Delivery', value: 'RESTAURANT_DELIVERY' }
  ];

  const [serviceType, setServiceType] = useState(service.serviceType);

  const [serviceNameError, setServiceNameError] = useState(false);

  React.useEffect(() => {
    if (!(serviceName === service.serviceName && serviceType === service.serviceType)) {
      const existingServices = _.filter(hours, (obj: IBizHour) => obj.serviceName.toLowerCase() === serviceName.toLowerCase());

      let flag = false;

      existingServices.forEach((service) => {
        if (service.serviceType === serviceType) {
          setServiceNameError(true);
          flag = true;
        }
      });

      if (!flag) {
        setServiceNameError(false);
      }
    } else {
      setServiceNameError(false);
    }
  }, [hours, service.serviceName, service.serviceType, serviceName, serviceType]);

  const dayConverter: any = {
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
    7: 'Sun'
  };

  const handleTimeChange = (value: string, day: number, timeType: string) => {
    const arr = [...businessHours];
    let newHour = -1;
    let newMin = -1;
    if (value.trim() !== '') {
      const [_hour, _min] = value.split(':');
      newHour = parseInt(_hour);
      newMin = parseInt(_min);
    }
    if (timeType === 'open') {
      setBusinessHours(
        arr.map((hour: IBizHourSlot) => {
          if (hour.openDay === day) {
            return {
              ...hour,
              openHour: newHour,
              openMinute: newMin
            };
          } else return hour;
        })
      );
    } else if (timeType === 'close') {
      setBusinessHours(
        arr.map((hour: IBizHourSlot) => {
          if (hour.openDay === day) {
            return {
              ...hour,
              closeHour: newHour,
              closeMinute: newMin
            };
          } else return hour;
        })
      );
    }
  };

  const checkError = (dayOfWeek: number, timeType: string) => {
    if (timeType === 'close') {
      const day: IBizHourSlot = _.filter(businessHours, (hour: IBizHourSlot) => hour.openDay === dayOfWeek)[0];

      if (day.openHour !== -1 && day.openMinute !== -1 && day.closeHour === -1 && day.closeMinute === -1) {
        return true;
      }

      // Add one day if end time is on next day
      const close = new Date(0, 0, day.openDay !== day.closeDay ? 1 : 0, day.closeHour, day.closeMinute, 0);

      const openHour = day.openHour;
      const openMinute = day.openMinute;
      if (openHour !== -1 && openMinute !== -1) {
        let minTime = null;

        minTime = new Date(0, 0, 0, openHour, openMinute);

        if (!(close > minTime)) {
          return true;
        }
      }
    } else {
      const curDay: IBizHourSlot = _.filter(businessHours, (hour: IBizHourSlot) => hour.openDay === dayOfWeek)[0];
      const prevDay: IBizHourSlot = _.filter(businessHours, (hour: IBizHourSlot) => hour.openDay === (dayOfWeek === 1 ? 7 : dayOfWeek - 1))[0];

      if (prevDay) {
        const maxDate = new Date(0, 0, 0, curDay.openHour, curDay.openMinute);
        const minDate = new Date(0, 0, 0, prevDay.closeHour, prevDay.closeMinute);

        if (prevDay.openDay !== prevDay.closeDay && minDate > maxDate) {
          return true;
        }
      }
    }

    return false;
  };

  /* set open field on switch click */
  const handleSwitchChange = (day: number) => {
    let arr = [...businessHours];

    if (_.filter(arr, (hour: IBizHourSlot) => hour.openDay === day).length > 0) {
      arr = _.filter(arr, (hour: IBizHourSlot) => hour.openDay !== day);
    } else {
      arr.splice(day - 1, 0, {
        openDay: day,
        closeDay: day,
        openHour: -1,
        openMinute: -1,
        closeHour: -1,
        closeMinute: -1
      });
    }

    setBusinessHours(arr);
  };

  /* save current row to copy to other rows */
  const startCopying = (day: number) => {
    const copyd = _.filter(businessHours, (hour: IBizHourSlot) => hour.openDay === day)[0];
    /* open modal only if values set */
    if (copyd.openHour !== -1 && copyd.openMinute !== -1 && copyd.closeHour !== -1 && copyd.closeMinute !== -1) {
      setCopyDay(copyd);
      setCopying(true);
      // setShowApplyDialog(true);
    }
  };

  const cancelCopying = () => {
    // setShowApplyDialog(false);
    setSelectedDays([]);
    setCopyDay(undefined);
    setCopying(false);
  };

  // Manage selected Days on checkbox change
  const handleCheckBoxChange = (day: number) => {
    if (selectedDays.indexOf(day) < 0) {
      setSelectedDays((oldval) => {
        return [...oldval, day];
      });
    } else {
      setSelectedDays((oldval) => {
        return _.filter(oldval, (d) => d !== day);
      });
    }
  };

  const handleNextDayChange = (day: number) => {
    const hours = [...businessHours];

    setBusinessHours(
      _.map(hours, (hour: IBizHourSlot) => {
        const { openDay, closeDay } = hour;

        if (openDay === day) {
          const nextDay = openDay === 7 ? 1 : openDay + 1;
          return {
            ...hour,
            closeDay: closeDay === openDay ? nextDay : openDay
          };
        } else {
          return hour;
        }
      })
    );
  };

  // Apply copied row to selected rows
  const applyChangesToSelected = () => {
    let arr = [...businessHours];
    if (selectedDays.length > 0 && copyDay) {
      let selected = [...selectedDays];

      /* copy in the open days */
      arr = _.map(arr, (hour: IBizHourSlot) => {
        if (selected.indexOf(hour.openDay) > -1) {
          selected = selected.filter((day: number) => day !== hour.openDay);
          let closeDay = hour.openDay;
          if (copyDay.openDay !== copyDay.closeDay) {
            closeDay = hour.openDay !== 7 ? hour.openDay + 1 : 1;
          }
          return {
            ...copyDay,
            /* copy only timings and not day of week */
            openDay: hour.openDay,
            closeDay
          };
        } else return hour;
      });

      /* days which are not open are still remaining  */
      selected.forEach((day: number) => {
        let closeDay = day;
        if (copyDay.openDay !== copyDay.closeDay) {
          closeDay = day !== 7 ? day + 1 : 1;
        }
        arr.push({
          ...copyDay,
          openDay: day,
          closeDay
        });
      });

      setBusinessHours(arr);
    }
    cancelCopying();
  };

  // Save after making all the changes
  const saveChanges = () => {
    const bizHours = businessHours.filter((hour: IBizHourSlot) => hour.openHour !== -1 && hour.openMinute !== -1 && hour.closeHour !== -1 && hour.closeMinute !== -1);
    editService({
      serviceName,
      serviceType,
      hours: bizHours
    });
    handleClose();
  };

  const modalClose = () => {
    handleClose();
    setBusinessHours([]);
    cancelCopying();
  };

  const disableBtn = () => {
    if (serviceName === '' || serviceNameError) {
      return true;
    }

    if (businessHours.filter((hour: IBizHourSlot) => hour.openHour !== -1 && hour.openMinute !== -1 && hour.closeHour !== -1 && hour.closeMinute !== -1).length === 0) {
      return true;
    }

    let error = false;
    for (let i = 0; i < 7; i++) {
      const hour = businessHours[i];
      if (hour) {
        if (checkError(hour.openDay, 'close') || checkError(hour.openDay, 'open')) {
          error = true;
          break;
        }
      }
    }

    return error;
  };

  const renderDays = () => {
    const output = [];

    for (let i = 1; i <= 7; i++) {
      const day = _.filter(businessHours, (hour: IBizHourSlot) => hour.openDay === i)[0];

      if (day) {
        const openTimeError = checkError(day.openDay, 'open');
        const closeTimeError = checkError(day.openDay, 'close');

        output.push(
          <Grid container={true} className={classes.dayContainer} key={i}>
            <Grid item={true} xs={1} className={classes.day}>
              <Typography variant="body1">{dayConverter[day.openDay]}</Typography>
            </Grid>
            <Grid item={true} xs={3}>
              <TimePicker
                hour={day.openHour}
                minute={day.openMinute}
                onChange={(value: string) => {
                  handleTimeChange(value, day.openDay, 'open');
                }}
                error={openTimeError}
              />
            </Grid>
            <Grid item={true} xs={1}>
              <Select value={day.closeDay} onChange={() => handleNextDayChange(day.openDay)}>
                <MenuItem value={day.openDay}>{dayConverter[day.openDay]}</MenuItem>
                <MenuItem value={day.openDay !== 7 ? day.openDay + 1 : 1}>{dayConverter[day.openDay !== 7 ? day.openDay + 1 : 1]}</MenuItem>
              </Select>
            </Grid>
            <Grid item={true} xs={3}>
              <TimePicker
                hour={day.closeHour}
                minute={day.closeMinute}
                onChange={(value: string) => {
                  handleTimeChange(value, day.openDay, 'close');
                }}
                error={closeTimeError}
              />
            </Grid>
            <Grid item={true} xs={2} className={classes.switchContainer}>
              <Switch checked={true} onChange={() => handleSwitchChange(day.openDay)} color="secondary" />
            </Grid>

            <Grid item={true} xs={2}>
              {copying && copyDay && copyDay.openDay !== i && (
                <Checkbox
                  onChange={() => {
                    handleCheckBoxChange(i);
                  }}
                  color="primary"
                />
              )}

              {!(copying && copyDay && copyDay.openDay !== day.openDay) && (
                <Button variant="contained" color="secondary" className={classes.copyBtn} onClick={() => startCopying(day.openDay)} disabled={openTimeError || closeTimeError}>
                  Copy To
                </Button>
              )}
            </Grid>
          </Grid>
        );
      } else {
        output.push(
          <Grid container={true} className={classes.dayContainer} key={i}>
            <Grid item={true} xs={1} className={classes.day}>
              <Typography variant="body1">{dayConverter[i]}</Typography>
            </Grid>
            <Grid item={true} xs={7} className={classes.timeclosebtn}>
              <Typography variant="body1">Close</Typography>
            </Grid>
            <Grid item={true} xs={2} className={classes.switchContainer}>
              <Switch checked={false} onChange={() => handleSwitchChange(i)} color="secondary" />
            </Grid>
            <Grid item={true} xs={2}>
              {copying && copyDay && copyDay.openDay !== i && (
                <Checkbox
                  onChange={() => {
                    handleCheckBoxChange(i);
                  }}
                  color="primary"
                />
              )}
            </Grid>
          </Grid>
        );
      }
    }

    return output;
  };

  const days = renderDays();

  return (
    <Dialog open={open} setOpen={setOpen} dialogProps={{ fullWidth: true, maxWidth: 'sm', disableBackdropClick: true }}>
      <Dialog.DialogContent className={classes.dialogContent}>
        <Grid className={classes.contentContainer}>
          {service.hours.length !== 0 && (
            <>
              <TextField
                label="Service"
                variant="outlined"
                value={serviceName}
                onChange={(e: any) => setServiceName(e.target.value)}
                error={serviceNameError}
                helperText={serviceNameError ? 'Service exists for selected service type.' : ''}
              />
              {/* <RadioGroup value={serviceType} onChange={(e: any) => setServiceType(e.target.value)}>
                <Grid container={true}>
                  {_.map(serviceTypes, (type: any, index) => {
                    return <FormControlLabel value={type.value} control={<Radio />} label={type.label} key={index} />;
                  })}
                </Grid>
              </RadioGroup> */}
            </>
          )}
          <Grid container={true} className={classes.tableHeader}>
            <Grid item={true} xs={1}>
              <Typography variant="body1">Day</Typography>
            </Grid>
            <Grid item={true} xs={3}>
              <Typography variant="body1">Start time </Typography>
            </Grid>
            <Grid item={true} xs={4}>
              <Typography variant="body1">End time </Typography>
            </Grid>
            <Grid item={true} xs={3} className={classes.alignCenter}>
              <Typography variant="body1">Open/ Close</Typography>
            </Grid>
          </Grid>
          {days}
        </Grid>
      </Dialog.DialogContent>
      <Dialog.DialogActions showBtns={false}>
        <Grid container={true} className={classes.modalBtns}>
          {copying ? (
            <>
              <Button variant="outlined" color="default" onClick={cancelCopying}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={applyChangesToSelected}>
                Copy
              </Button>
            </>
          ) : (
            <>
              {service.hours.length !== 0 && (
                <Button variant="outlined" color="default" onClick={modalClose}>
                  Cancel
                </Button>
              )}
              <Button variant="contained" color="primary" onClick={saveChanges} disabled={disableBtn()}>
                Ok
              </Button>
            </>
          )}
        </Grid>
      </Dialog.DialogActions>
    </Dialog>
  );
};

export default BusinessHoursDialog;
