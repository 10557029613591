export const PaymentOptionsArray = [
  'ONLINE_STRIPE',
  'ONSITE_CASH',
  'ONSITE_VISA_CARD',
  'ONSITE_MASTER_CARD',
  'ONSITE_AMERICAN_EXPRESS_CARD',
  'ONSITE_DISCOVER_CARD',
  'ONSITE_APPLE_PAY',
  'ONSITE_GOOGLE_PAY'
];

export type PaymentOptions =
  | 'ONLINE_STRIPE'
  | 'ONSITE_CASH'
  | 'ONSITE_VISA_CARD'
  | 'ONSITE_MASTER_CARD'
  | 'ONSITE_AMERICAN_EXPRESS_CARD'
  | 'ONSITE_DISCOVER_CARD'
  | 'ONSITE_APPLE_PAY'
  | 'ONSITE_GOOGLE_PAY';

export type ITabOptions = 'takeoutPaymentSetting' | 'dineoutPaymentSetting' | 'deliveryPaymentSetting';

export interface IPaymentSetting {
  onlinePaymentTypes: PaymentOptions[];
  onlinePaymentFeeAmount: string;
  onlinePaymentFeeThreshold: string;
  onsitePaymentTypes: PaymentOptions[];
  tipPercentOptions: number[];
  tipDefaultPercentIndex: number;
  addOnlinePaymentFee: boolean;
  enable: boolean;
  disableOrderDetailDisplay: boolean;
}

export interface IInitialValueType {
  takeoutPaymentSetting: IPaymentSetting;
  dineoutPaymentSetting: IPaymentSetting;
  deliveryPaymentSetting: IPaymentSetting;
  hasStripeAccount: boolean;
  stripeInProgress: boolean;
  deliverySetting: IDeliverySetting;
  takeoutWaitMinutes: number;
}

export interface IDeliverySetting {
  waitMinutes: number;
  freeDeliveryAmount: string;
  minDeliveryAmount: string;
  fees: {
    mile: number;
    fee: string;
  }[];
}
