import React, { useState, useImperativeHandle } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { IBizHour } from '../types';
import * as _ from 'lodash';
import ServiceDialog from './ServiceDialog';
import BusinessHoursTable from './BusinessHoursTable';
import BusinessHoursDialog from './BusinessHoursDialog';
import { useUpdateRestaurantMutation } from 'graphql/hooks/restaurant.hooks';
import { Dialog } from '@lokobee/lokobee-ui';
import { useStyles } from './style';
import { useFormik } from 'formik';
import validate from './validations';
import { useSnackbar } from 'notistack';
import { BizHour, ServiceType } from 'generated/types';


interface IProps {
  initialValues: IBizHour[];
  nextStep: () => void;
}

const BusinessHours = React.forwardRef(({ initialValues, nextStep }: IProps, ref) => {
  const classes = useStyles();

  const { updateRestaurant } = useUpdateRestaurantMutation('BUSINESS_HOURS');

  const formik = useFormik({
    initialValues: {
      bizHours: initialValues
    },
    validationSchema: validate,
    enableReinitialize: true,
    onSubmit: (formValues) => handleFormSubmit(formValues)
  });
  const copyBizHours = (dineInHours: BizHour[]) => {
    let copiedHours: BizHour[] = [];
    dineInHours.forEach((hours) => {
      copiedHours.push({
        ...hours,
        serviceType: ServiceType.RestaurantDelivery
      });
      copiedHours.push({
        ...hours,
        serviceType: ServiceType.RestaurantPickUp
      });
      copiedHours.push({
        ...hours,
        serviceType: ServiceType.RestaurantDineIn
      });
    });
    return copiedHours;
  };
  const handleFormSubmit = async (formValues: any) => {
    const response = await updateRestaurant({
      variables: {
        input: {
           bizHours: copyBizHours(formValues.bizHours)
        }
      }
    });

    if (response) {
      nextStep();
    }
  };

  const [curServiceIndex, setCurServiceIndex] = useState<number>(-1);

  const [openServiceDialog, setOpenServiceDialog] = useState(false);

  const [openBusinessHoursDialog, setOpenBusinessHoursDialog] = useState(false);

  const [deleteIndex, setDeleteIndex] = useState<number>(-1);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { bizHours } = formik.values;

  /* 
    Get all service names added by user until now for autocomplete dropdown in service dialog 
  */
  const services: string[] = Array.from(new Set(_.map(bizHours, (obj: IBizHour) => obj.serviceName.charAt(0).toUpperCase() + obj.serviceName.slice(1))));

  /*  Add new service */
  const addService = (service: string, type: string) => {
    if (service.trim() !== '') {
      formik.setValues({
        bizHours: [...bizHours, { serviceName: service, serviceType: type, hours: [] }]
      });
      setOpenServiceDialog(false);
      businessHoursDialogOpen(bizHours.length);
    }
  };

  /* Edit Service */
  const editService = (editedService: IBizHour) => {
    /* replacing edited hours in current service */
    /* called from business hours dialog after editing */

    const oldVal = [...bizHours];
    oldVal[curServiceIndex] = editedService;

    formik.setValues({
      bizHours: oldVal
    });
  };

  const deleteDialogOpen = (index: number) => {
    setDeleteIndex(index);
    setOpenDeleteDialog(true);
  };

  /* Delete service */
  const deleteService = () => {
    const arr = [...bizHours];
    arr.splice(deleteIndex, 1);

    formik.setValues({
      bizHours: arr
    });
    cancelDelete();
  };

  const cancelDelete = () => {
    setDeleteIndex(-1);
    setOpenDeleteDialog(false);
  };

  /* Open business hours dialog to edit data */
  const businessHoursDialogOpen = (index: number) => {
    /* used when changes saved */
    setCurServiceIndex(index);
    setOpenBusinessHoursDialog(true);
  };

  /* reset current service when business hours dialog closed */
  const businessHoursDialogClose = () => {
    setOpenBusinessHoursDialog(false);
    setCurServiceIndex(-1);
  };

  useImperativeHandle(ref, () => {
    return {
      setOpenServiceDialog,
      submitForm: () => {
        return formik.submitForm();
      }
    };
  });

  return (
    <>
      <Grid className={classes.businessHours} container={true}>
        {_.map(bizHours, (hour: IBizHour, index: number) => {
          return (
            <Grid item={true} xs={12} sm={6} md={4} key={index} className={classes.serviceTable}>
              <BusinessHoursTable service={hour} onEdit={() => businessHoursDialogOpen(index)} onDelete={() => deleteDialogOpen(index)} />
            </Grid>
          );
        })}
        <ServiceDialog open={openServiceDialog} setOpen={setOpenServiceDialog} services={services} addService={addService} businessHours={bizHours} />
        {curServiceIndex !== -1 && (
          <BusinessHoursDialog
            open={openBusinessHoursDialog}
            setOpen={setOpenBusinessHoursDialog}
            handleClose={businessHoursDialogClose}
            service={bizHours[curServiceIndex]}
            editService={editService}
            hours={bizHours}
          />
        )}
        <Dialog open={openDeleteDialog} setOpen={setOpenDeleteDialog}>
          <Dialog.DialogContent>
            <Typography variant="body1">Are you sure you want to delete this service ?</Typography>
          </Dialog.DialogContent>
          <Dialog.DialogActions saveBtnText="Yes" onSave={deleteService} cancelBtnText="No" onCancel={cancelDelete} />
        </Dialog>
      </Grid>
    </>

  );
});

export default BusinessHours;
